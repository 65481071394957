@use "src/styles/vars.scss" as *;

.app-position-card {
	border-radius: 12px;
	border: 1px solid var(--c-dark-grey);
	background: var(--c-box-grey);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.position-card {
		padding: 40px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;

		@include max-width($scr-xs) {
			padding: 30px;
		}

		&__description {
			margin: 0;
			padding-top: 10px;
			color: var(--theme-text-color-2nd);
			font-size: var(--text-size-m);
			font-weight: var(--text-weight-regular);
			overflow-wrap: break-word;
			word-break: break-word;

			@include max-width($scr-xs) {
				font-size: var(--text-size-xs);
			}
		}

		&__title {
			margin: 0;
			color: var(--c-white);
			font-weight: var(--text-weight-bold);
			font-size: calc(var(--text-size-l) + 2px);
			overflow-wrap: break-word;
			word-break: break-word;

			@include max-width($scr-xs) {
				font-size: var(--text-size-body);
			}
		}

		&__section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-top: 20px;

			@include max-width($scr-xs) {
				padding-top: 10px;
			}
		}

		&__btn {
			margin-top: 5px;
			padding: 18px 24px;
			width: 100%;
			.button__label {
				font-size: var(--text-size-body);
			}

			@include max-width($scr-xc) {
				height: 38px;
				padding: 10px;
				.button__label {
					font-size: var(--text-size-xs);
				}
			}
		}

		.apply-button {
			padding: 18px 24px;
			width: 100%;

			@include max-width($scr-xs) {
				height: 38px;
				padding: 10px;
				.button__label {
					font-size: var(--text-size-xs);
				}
			}
		}
	}

	.job-type {
		color: var(--theme-highlight);
	}
}
